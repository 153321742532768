#app {
  animation: cloak-in 0.5s;
}

#header {
  margin: 40px 20px;
  margin-top: 80px;
  @include media(PC){
    position: relative;
    left: 10%;
    width: calc(100% - 20%);
    padding-top: 10%;
  }
  .logo {
    max-width: 300px;
    @include media(PC){
      max-width: 100%;
    }
    h1 {
      margin-bottom: 15px;
      img {
        width: 100%;
        @include media(PC){
          width: auto;
        }
      }
    }
    p {
      font-size: 1.3rem;
      font-weight: bold;
      letter-spacing: 0.15em;
    }
    a {
      text-decoration: none;
      color: #000;
    }
  }
  .navi {
    ul {
      margin-top: 50px;
      li {
        margin-bottom: 30px;
        @include media(PC){
          margin-bottom: 40px;
        }
        a {
          text-decoration: none;
          color: #000;
          font-weight: 600;
          letter-spacing: 0.1em;
          font-size: 3.0rem;
          line-height: 1;
          @include media(PC){
            font-size: 4.0rem;
            position: relative;
            display: inline-block;
            &:before {
              content:"";
              width: 0%;
              height: 100%;
              background-color: #000;
              display: block;
              position: absolute;
              z-index: -1;
              transition: all .1s ease;
            }
            &:hover {
              color: #fff;
              &:before {
                width: 100%;
              }
            }
          }
          &.router-link-active {
            position: relative;
            display: inline-block;
            color: #fff;
            &:before {
              content:"";
              width: 100%;
              height: 100%;
              background-color: #000;
              display: block;
              position: absolute;
              z-index: -1;
              transition: all .1s ease;
            }
          }
        }
      }
    }
  }
}

#footer {
  margin: 40px 20px;
  @include media(PC){
    width: calc(100% - 20%);
    position: relative;
    left: 10%;
    // bottom: 10%;
  }
  .contact {
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    a {
      text-decoration: none;
      color: #000;
      position: relative;
      display: inline-block;
      &:before {
        content:"";
        width: 0%;
        height: 100%;
        background-color: #000;
        display: block;
        position: absolute;
        z-index: -1;
        transition: all .1s ease;
      }
      &:hover {
        color: #fff;
        &:before {
          width: 100%;
        }
      }
    }
    p {
      margin-bottom: 10px;
    }
  }
  .cr {
    margin-top: 30px;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    font-weight: bold;
  }
}

.contents {
  margin: 20px 20px;
  font-size: 1.7rem;
  line-height: 2;
  letter-spacing: 0.05em;
  padding: 0px 0;
  // border-top: 2px solid #000;
  // border-bottom: 2px solid #000;
  @include media(PC){
    border: 0px solid #000;
    padding: 0;
    position: relative;
    left: 10%;
    width: 50%;
    // position: fixed;
    // right: 10%;
    // top: 10%;
    // width: 30%;
    font-size: 1.8rem;
    line-height: 2;
    letter-spacing: 0.05em;
  }
  p {
    margin-bottom: 20px;
    font-weight: bold;
  }
}

.recruit {
  margin-top: 50px;
  margin-bottom: 40px;
  a {
    display: flex;
    font-size: 3rem;
    font-weight: bold;
    padding: 10px 20px;
    line-height: 1.4;
    border: 4px solid #000;
    text-decoration: none;
    color: $dark-color;
    flex-direction: column;

    span {
      font-size: 2.0rem;
      padding-bottom: 5px;
    }

    br {
      display: none;

      @include media(PC){
        display: inherit;
      }
    }

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.router-transition-enter-active {
  transition: all .3s ease;

}
.router-transition-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.router-transition-enter, .router-transition-leave-to
/* router-transition-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
  transition: all .2s ease;
}

#app[v-cloak] {
  opacity: 0;
}

@keyframes cloak-in {
  0% { opacity: 0;}
}



#index {
  position: relative;
  // &:before {
  //   content:"";
  //   width: 100vw;
  //   height: 100vh;
  //   background-color: red;
  //   display: block;
  // }
}
.p5Canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -9999;
  width: 100% !important;
  height: auto !important;
}


#recruit {

  .back-btn {
    position: fixed;
    top:0;
    left:0;
    font-size: 3.0rem;
    font-weight: bold;
    line-height: 1;
    background-color: #fff;
    padding: 10px;

    a {
      text-decoration: none;
      color: #000;

            text-decoration: none;
      color: #000;
      position: relative;
      display: inline-block;
      &:before {
        content:"";
        width: 0%;
        height: 100%;
        background-color: #000;
        display: block;
        position: absolute;
        z-index: -1;
        transition: all .1s ease;
      }
      &:hover {
        color: #fff;
        &:before {
          width: 100%;
        }
      }
    }
  }

  .recruit-cover {
    h2 {
      font-weight: bold;
      font-size: 2.4rem;
    }
    h3 {
      font-weight: bold;
      line-height: 1.5;
      font-size: 3.0rem;
      color: #fff;
      background-color: #000;

      br {
        display: none;

        @include media(PC){
          display: inherit;
        }
      }

      @include media(PC){
        font-size: 4.0rem;
      }
    }
    p {
      font-size: 2.0rem;
      line-height: 1.6;
      margin-top: 20px;
    }
  }
  .recruit-contents {
    margin-top: 80px;
    h2 {
      font-size: 2.0rem;
      font-weight: bold;
    }
  }
  .recruit-block {
    margin-top: 30px;

    + .recruit-block {
      margin-top: 60px;
    }

    + h2 {
      margin-top: 80px;
    }
    h3 {
      font-size: 3.0rem;
      font-weight: bold;
      color: #fff;
      background-color: #000;
      padding: 10px 5px;
      display: inline-block;
      line-height: 1;
    }
    p {
      margin-top: 15px;
      font-size: 1.4rem;
    }

    dl {
      display: flex;
      font-size: 1.4rem;
      font-weight: bold;

      + dl {
        margin-top: 20px;
      }

      dt {
        width: 20%;
        display: flex;
        align-items: center;

        @include media(PC){
          justify-content: flex-end;
          padding-right: 10px;
        }
      }

      dd {
        width: 80%;
        border: 2px solid #000;
        padding: 10PX;
      }

    }
  }

  .entry {
    margin-top: 60px;
    margin-bottom: 120px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding: 20px;
      font-size: 2.0rem;
      background-color: #000;
      color: #fff;
      border: 4px solid #000;
      font-weight: bold;

      @include media(PC){
        font-size: 3.0rem;
      }

      &:hover {
        background: #fff;
        color: #000;
        border: 4px solid #000;
      }
    }
  }
}
