//-----------------------------------------------------------------------------------
//
//  config.scss
//
//  1. Base Colors
//  2. Line Height Variables
//  3. Margins
//  4. Font Families
//  5. Breakpoint Setting
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. Base Colors
//////////////////////////////////////////////////////////

$primary-color:     rgb(40, 40, 40);
$accent-color:      #17CE7D;
$light-color:       #fff;
$dark-color:        #131313;

//////////////////////////////////////////////////////////
// 2. Line Height Variables
//////////////////////////////////////////////////////////

$line-height:              1.6;

//////////////////////////////////////////////////////////
// 3. Margins
//////////////////////////////////////////////////////////

$margin:       15px;

//////////////////////////////////////////////////////////
// 4. Font Families
//////////////////////////////////////////////////////////

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700&subset=japanese');

$body-font: 'Noto Sans JP', "游ゴシック", YuGothic, "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$heading-font: 'Noto Sans JP','Roboto Condensed', "Helvetica Neue", Helvetica, Arial, serif;


//////////////////////////////////////////////////////////
// 5. Breakpoint Setting
//////////////////////////////////////////////////////////

$breakpoints: (
    'TB': 'screen and (min-width: 420px)',
    'PC': 'screen and (min-width: 768px)',
    'LG': 'screen and (min-width: 1200px)'
) !default;

