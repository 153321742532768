//-----------------------------------------------------------------------------------
//
//  common.scss
//
//  1. font style
//  2. layout style
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. font style
//////////////////////////////////////////////////////////

html, body {
    font-size: 62.5%;
    font-family: $body-font;
    color: $dark-color;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
  }

html {
  // background-image: url("../images/23040802.jpg");
  background-size: 100%;
}

::selection {
    background: $primary-color;
    color: $light-color;
    text-shadow: none;
}



